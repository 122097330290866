@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
body,*[class^="Mui"] {
  margin: 0;
  font-family: 'Poppins', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-message{
  z-index: 99999999999999 !important;
}
:root {
  --main: #bf6159;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
/* Font sizes */
.f-9 {
  font-size: 9px !important;
}
.f-10 {
  font-size: 10px !important;
}
.f-12 {
  font-size: 12px !important;
}

.f-16 {
  font-size: 16px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-36 {
  font-size: 36px !important;
}
/* Font Weight */
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
/* spacing */
.spacing13 {
  margin-top: 13px;
}
.spacing27 {
  margin-top: 27px;
}
.spacing62 {
  margin-top: 62px;
}
/* Width */
.w-15 {
  width: 15px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
  border-radius: 0%;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MuiButton-containedPrimary {
  background: #bf6159 !important;
  background: var(--main) !important;
}
.MuiButton-outlinedPrimary {
  border-color: #bf6159 !important;
  border-color: var(--main) !important;
  color: #bf6159 !important;
  color: var(--main) !important;
  border-radius: 0% !important;
}
.forgot-password,
.forgot-password:hover {
  color: #282c34 !important;
  text-decoration: none;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.ant-input:hover,
.ant-input:focus,
.ant-input-number-focused,
.ant-select-focused,
.ant-select-focused .ant-select-selector,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-select:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-upload.ant-upload-select-picture-card,
.ant-select:focus {
  border-color: #bf6159 !important;
  border-color: var(--main) !important;
}
.ant-upload.ant-upload-select-picture-card * {
  color: #bf6159 !important;
  color: var(--main) !important;
  width: 100% !important;
}
.ant-modal-mask {
  z-index: 999989 !important;
}
.ant-modal-wrap {
  z-index: 999999 !important;
}
.ant-picker-focused,
.ant-input:focus,
.ant-input-number-focused,
.ant-select-focused .ant-select-selector,
.ant-select-focused {
  box-shadow: 0 0 0 2px #bf61591a !important;
}
.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #bf6159 !important;
  background-color: var(--main) !important;
}
.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #bf6159 !important;
  border-color: var(--main) !important;
}
.ant-picker-today-btn {
  color: #bf6159 !important;
  color: var(--main) !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #bf6159 !important;
  color: var(--main) !important;
}
.font-20 {
  font-size: 20px;
}
.w-100 {
  width: 100% !important;
}
a,
a:hover {
  color: black;
  text-decoration: none;
}
.back,
.back:hover {
  color: black;
  text-decoration: none;
}
.back svg {
  margin-top: 20px;
}
/* .MuiFormControl-root {
  margin-top: 8px !important;
} */

.MuiCard-root {
  box-shadow: 0px 0px 6px #00000029;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #bf6159 !important;
  background-color: var(--main) !important;
}
.MuiLinearProgress-root {
  width: 100%;
}
.bg-main {
  background-color: #f1bd8e42 !important;
}

.MuiDrawer-paper a {
  color: black !important;
  text-decoration: none !important;
}
.mainContainer {
  padding-top: 90px !important;
}
.MuiBreadcrumbs-ol li a {
  color: #bf6159 !important;
  color: var(--main) !important;
  text-decoration: none !important;
}
.filters {
  border: 1px solid !important;
  border-radius: unset !important;
  padding: 3px 5px !important;
  font-size: 14px !important;
}
.filters .MuiIconButton-label {
  font-size: 16px !important;
}
.Filters-Show {
  display: block;
}
.Filters-Hide {
  display: none;
}
/* .MuiFormControl-root {
  margin-bottom: 8px !important;
} */
.MuiFormControl-root label {
  margin-bottom: 2px !important;
}
.imageUpload input {
  display: none;
}
.imageUpload {
  color: #bf6159;
  color: var(--main);
  border-bottom: 1px solid #bf6159;
  border-bottom: 1px solid var(--main);
  cursor: pointer;
}
.text-main {
  color: #bf6159 !important;
  color: var(--main) !important;
}
.MuiPopover-paper {
  border: 1px solid lightgrey !important;
}
.cursor {
  cursor: pointer;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: #ffffff !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #28db34 !important ;
  opacity: 1 !important;
}
input[type="checkbox"] + svg path {
  color: #bf6159 !important;
  color: var(--main) !important;
}
.MuiSelect-root.MuiOutlinedInput-input {
  padding: 10px 10px !important;
}
.MuiTab-root {
  padding-top: 34px !important;
}
.MuiOutlinedInput-root,
.MuiButton-containedPrimary {
  border-radius: 0px !important;
}
.mainContainer a:not(:disabled) {
  color: #bf6159 !important;
  color: var(--main) !important;
}
.mainContainer a[disabled] {
  pointer-events: none;
  cursor: auto;
  cursor: initial;
  color: #282c34 !important;
}
.chat-left .MuiBox-root {
  background-color: #bf615921;
}
.chat-right .MuiBox-root {
  background-color: #ef9b0a0f;
}
.error {
  margin-top: 3px;
  font-size: small;
  color: red;
}
.ck-content {
  min-height: 600px !important;
}
.auto {
  width: 100%;
  height: auto;
}
input[type="number"] {
  -webkit-appearance: text !important;
          appearance: text !important;
}
.not-seen {
  width: 10px;
  height: 10px;
  /* border: 1px solid green; */
  background-color: #00800094;
  border-radius: 50%;
  display: inline-block;
  margin: 3px;
}

.ant-btn-default {
  border-color: #bf6159 !important;
  border-color: var(--main) !important;
  color: #bf6159 !important;
  color: var(--main) !important;
}
span[class*="PrivateTabIndicator-colorSecondary"] {
  background-color: #242424 !important;
}
.cursor-pointer {
  cursor: pointer;
}
/* Kisok CSS */
.KChange-password .ant-modal-title,
.RequestReturn .ant-modal-title {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #bf6159;
  color: var(--main);
}
.KChange-password .anticon-close {
  font-size: 25px;
  font-weight: 700;
  color: #bf6159;
  color: var(--main);
}
.KChange-password .ant-modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.KChange-password .KChange-password-body {
  max-width: 400px;
  margin: auto;
}
.KChange-password .KChange-password-body .MuiFormControl-root {
  margin-bottom: 8px;
}
.kisok,
.admin {
  max-width: calc(100% - 240px) !important;
  width: calc(100% - 240px) !important;
}
/* .kisok .mainContainer .MuiPaper-root:first-of-type {
  min-height: 600px !important;
} */
.kisok p,
.ant-modal-root p {
  margin-bottom: 3px !important;
}
.kisok .ant-tabs-tab-btn,
.kisok .ant-tabs-tab-btn:hover {
  color: black !important;
}
.kisok .ant-tabs-ink-bar {
  background-color: black;
}
.rdt_Table,
.jPffGe {
  max-width: calc(100% - 1px) !important;
  width: calc(100% - 1px) !important;
}
.RequestReturn-infinite-container {
  height: 300px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
}

/* /////////////////////////// */
.bg-configs {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

